<template>
  <cs-page class="views-product-payOrder text-center bg-white">
    <ProcessStep :step="2" />
    <a-spin
      tip="信息加载中..."
      :spinning="!formModel.id"
      class="mx-auto"
      style="width: 1120px"
    >
      <div class="product-body">
        <div class="pay-tips">
          <h3>提交成功，请您尽快付款！</h3>
          <span>请您在提交订单后48 小时内完成支付，否则订单会自动取消</span>
        </div>
        <div class="pay-info">
          <a-row>
            <a-col :span="12">
              <div class="text-start info-price">
                <h4>
                  应付金额: <small>¥ </small> <b>{{ formModel.amount }}</b>
                </h4>
                <span> 订单编号：{{ formModel.orderNumber }} </span>
              </div>
            </a-col>
            <a-col :span="12" class="text-end">
              <button class="btnCheckedPay" @click="paySuccessClick">
                我已支付完成
              </button>
            </a-col>
          </a-row>
        </div>
        <a-row :gutter="160">
          <a-col :span="12" class="text-end">
            <div class="pay-qrcode" v-if="formModel.qrCodeAlipay">
              <cs-qrcode
                :code="formModel.qrCodeAlipay"
                title="支付宝支付"
                :size="190"
              />
              <cs-icon
                name="icon-zhifubao"
                type="symbol"
                style="width: auto; height: 30px; margin-left: -57px"
              />
            </div>
          </a-col>
          <a-col :span="12" class="text-start">
            <div class="pay-qrcode" v-if="formModel.qrCodeWeixin">
              <cs-qrcode
                :code="formModel.qrCodeWeixin"
                title="微信支付"
                :size="190"
              />
              <cs-icon
                name="icon-weixinzhifu1"
                type="symbol"
                style="width: auto; height: 30px; margin-left: -57px"
              />
            </div>
          </a-col>
        </a-row>
      </div>
    </a-spin>
  </cs-page>
</template>
<script>
export default {
  components: {
    ProcessStep: () => import("@/components/product/ProcessStep"),
  },
  data() {
    return {
      formModel: {
        id: "",
        orderNumber: "",
        amount: "",
        qrCodeAlipay: "",
        qrCodeWeixin: "",
      },
    };
  },
  created() {
    this.initData();
  },
  methods: {
    async initData() {
      const { id } = this.$route.query;
      const res = await this.api.service.fpc_oapi_order_orderConfirm(
        {},
        { id }
      );
      this.formModel.id = id;
      // 如果是已经付款，则跳转到成功页面
      if (res.code == "20505") {
        return this.$router.replace({
          path: "/product/success",
          query: {
            id,
          },
        });
      }
      await this.api.toast(res, 0);
      const { data } = res;
      this.formModel.orderNumber = data.orderNumber;
      this.formModel.amount = data.amount;
      this.formModel.qrCodeAlipay = data.qrCodeAlipay;
      this.formModel.qrCodeWeixin = data.qrCodeWeixin;
    },
    async paySuccessClick() {
      const { id } = this.formModel;
      const res = await this.api.service.fpc_oapi_order_getOrderStatus(
        {},
        {
          id,
        }
      );
      const { data } = res;
      if (data == 2) {
        this.$router.replace({
          path: "/product/success",
          query: {
            id,
          },
        });
      } else {
        this.api.confirm("订单未付款，请核实", 0);
      }
    },
  },
};
</script>
<style lang="less" scoped>
.views-product-payOrder {
  .product-body {
    width: 1100px;
    margin: 0 auto;
  }
  .pay-tips {
    border-bottom: 1px dashed #d7dbdd;
    padding-bottom: 30px;
    h3 {
      font-weight: bold;
      font-size: 20px;
    }
    span {
      color: #949898;
      font-size: 14px;
    }
  }
  .pay-info {
    margin: 30px 0;
    background: #f8fdfd;
    border: 1px solid #53cac2;
    .info-price {
      margin-left: 25px;
      margin-top: 13px;
      h4 {
        font-size: 16px;
        color: #202121;
        margin-bottom: 0;
        small {
          color: #ff5620;
          font-size: 16px;
          font-weight: bold;
        }
        b {
          color: #ff5620;
          font-size: 30px;
          letter-spacing: -0.5px;
          font-weight: 700;
        }
      }
      span {
        color: #949898;
        font-size: 14px;
      }
    }
    .btnCheckedPay {
      cursor: pointer;
      font-size: 16px;
      background: #00a497;
      color: white;
      border: none;
      width: 160px;
      height: 48px;
      margin: 25px;
    }
  }

  .pay-qrcode {
    width: 192px;
    height: 245px;
    border: 1px solid #e2e7f0;
    display: inline-block;
  }
}
</style>